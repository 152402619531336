export const groupedProducts = {
  hubble: [
    {
      value: "hubble",
      percent_off: "0",
      link: "",
      title: "Hubble Classic Lenses",
      image: "",
      price: "22.99",
      sale_price: "1.00",
      cc_product_id: "hubble",
      days: "30",
      quantity: 30
    },
    {
      value: "hydro",
      percent_off: "0",
      link: "",
      title: "Hydro by Hubble Contact Lenses",
      short_title: "Hydro By Hubble",
      image: "",
      price: "24.99",
      sale_price: "4.99",
      cc_product_id: "hydro",
      days: "30",
      quantity: 30
    },
    {
      value: "skyhy",
      percent_off: "0",
      link: "",
      title: "SkyHy by Hubble Contact Lenses",
      short_title: "SkyHy by Hubble",
      image: "",
      price: "29.99",
      sale_price: "9.99",
      cc_product_id: "skyhy",
      days: "30",
      quantity: 30
    },
  ],
  astigmatism: [
    {
      value: "brand-acuvue-moist-astigmatism",
      percent_off: "35",
      link: "https://www.contactscart.com/products/1-day-acuvue-moist-for-astigmatism",
      title: "1-Day Acuvue Moist for Astigmatism (30 pack)",
      image: "1-Day-Acuvue-Moist-Astigmatism-30pk",
      price: "44.99",
      sale_price: "29.24",
      cc_product_id: "1765072797796",
      days: "30",
      quantity: 30
    },
    {
      value: "brand-acuvue-moist-astigmatism-90",
      percent_off: "35",
      link: "",
      title: "1-Day Acuvue Moist for Astigmatism (90 pack)",
      image: "1-Day-Acuvue-Moist-Astigmatism-90pk",
      price: "104.99",
      sale_price: "68.24",
      cc_product_id: "3344340287588",
      days: "88",
      quantity: 90
    },
    {
      value: "acuvue-oasys-1-day-hydraluxe-astig-30",
      percent_off: "35",
      link: "https://www.contactscart.com/products/acuvue-oasys-1-day-for-astigmatism-30pk",
      title: "Acuvue Oasys 1-Day with Hydraluxe for Astigmatism (30 pack)",
      image: "Acuvue-Oasys-1-Day-Hydraluxe-Astigmatism-30pk",
      price: "54.99",
      sale_price: "35.74",
      cc_product_id: "3409322016868",
      days: "30",
      quantity: 30
    },
    {
      value: "acuvue-oasys-1-day-hydraluxe-astig-90",
      percent_off: "35",
      link: "https://www.contactscart.com/products/acuvue-oasys-1-day-with-hydraluxe-for-astigmatism-90pk",
      title: "Acuvue Oasys 1-Day with Hydraluxe for Astigmatism (90 pack)",
      image: "Acuvue-Oasys-1-Day-Hydraluxe-Astigmatism-90pk",
      price: "126.99",
      sale_price: "82.54",
      cc_product_id: "7165851992164",
      days: "88",
      quantity: 90
    },
    {
      value: "brand-acuvue-astigmatism",
      percent_off: "35",
      link: "https://www.contactscart.com/products/acuvue-oasys-for-astigmatism",
      title: "Acuvue Oasys for Astigmatism (6 pack)",
      image: "Acuvue-Oasys-Astigmatism-6pk",
      price: "52.99",
      sale_price: "34.44",
      cc_product_id: "1765080399972",
      days: "90",
      quantity: 6
    },
    {
      value: "brand-acuvue-vita-6pk-astig",
      percent_off: "35",
      link: "https://www.contactscart.com/products/acuvue-vita-for-astigmatism",
      title: "Acuvue Vita for Astigmatism (6 pack)",
      image: "Acuvue-Vita-Astigmatism-6pk",
      price: "74.99",
      sale_price: "48.74",
      cc_product_id: "3379060179044",
      days: "180",
      quantity: 6
    },
    {
      value: "bausch-lomb-astig",
      percent_off: "35",
      link: "https://www.contactscart.com/collections/top-brands/products/acuvue-oasys-with-hydraclear-plus-12pk",
      title: "Bausch + Lomb ULTRA for Astigmatism (6 pack)",
      image: "B+L-Ultra-Astigmatism-6pk",
      price: "69.99",
      sale_price: "45.49",
      cc_product_id: "3341501268068",
      days: "180",
      quantity: 6
    },
    {
      value: "biotrue-oneday-astig-30",
      percent_off: "35",
      link: "https://www.contactscart.com/collections/top-brands/products/acuvue-oasys-with-hydraclear-plus-12pk",
      title: "Biotrue ONEday Astigmatism (30 pack)",
      image: "Biotrue-ONEDay-Astigmatism-30pk",
      price: "32.99",
      sale_price: "21.44",
      cc_product_id: "3408913530980",
      days: "30",
      quantity: 30
    },
  ],
  popular: [
    {
      value: "brand-acuvue-moist",
      percent_off: "35",
      link: "https://www.contactscart.com/products/1-day-acuvue-moist-90pk",
      title: "1-Day Acuvue Moist (90 pack)",
      image: "1-Day-Acuvue-Moist-90pk",
      price: "82.99",
      sale_price: "53.94",
      cc_product_id: "2620861448292",
      days: "88",
      quantity: 90
    },
    {
      value: "brand-acuvue-oyasis-hydraluxe",
      percent_off: "35",
      link: "https://www.contactscart.com/products/acuvue-oasys-1-day",
      title: "Acuvue Oasys 1-Day with Hydraluxe (90 pack)",
      image: "Acuvue-Oasys-1-Day-Hydraluxe-90pk",
      price: "99.99",
      sale_price: "64.99",
      cc_product_id: "1765069357156",
      days: "90",
      quantity: 90
    },
    {
      value: "brand-dailies-aquacomfort-plus",
      percent_off: "25",
      link: "https://www.contactscart.com/products/dailies-aqua-comfort-plus-90pk",
      title: "DAILIES AquaComfort Plus (90 pack)",
      image: "DAILIES-AquaComfort-Plus",
      price: "79.99",
      sale_price: "60.00",
      cc_product_id: "1765114970212",
      days: "88",
      quantity: 90
    },
    {
      value: "brand-biotrue-presbyopia",
      percent_off: "35",
      link: "https://www.contactscart.com/products/biofinity-toric",
      title: "Biotrue ONEday for Presbyopia (90 pack)",
      image: "Biotrue-ONEday-Presbyopia-90pk",
      price: "96.99",
      sale_price: "63.04",
      cc_product_id: "3408938401892",
      days: "88",
      quantity: 90
    },
    {
      value: "brand-acuvue-hydraclear-plus",
      percent_off: "35",
      link: "https://www.contactscart.com/collections/top-brands/products/acuvue-oasys-with-hydraclear-plus-12pk",
      title: "Acuvue Oasys with Hydraclear Plus (6 pack)",
      image: "Acuvue-Oasys-Hydraclear-Plus-6pk",
      price: "49.99",
      sale_price: "32.49",
      cc_product_id: "1765078499428",
      days: "90",
      quantity: 6
    },
    {
      value: "dailies-total-one",
      percent_off: "20",
      link: "https://www.contactscart.com/collections/top-brands/products/acuvue-oasys-with-hydraclear-plus-12pk",
      title: "DAILIES TOTAL1 (90 pack)",
      image: "DAILIES-TOTAL1-90pk",
      price: "112.99",
      sale_price: "90.40",
      cc_product_id: "2620910534756",
      days: "88",
      quantity: 90
    },
    {
      value: "biotrue-oneday-90",
      percent_off: "35",
      link: "https://www.contactscart.com/collections/top-brands/products/acuvue-oasys-with-hydraclear-plus-12pk",
      title: "Biotrue ONEday (90 pack)",
      image: "Biotrue-ONEDay-90pk",
      price: "67.99",
      sale_price: "44.19",
      cc_product_id: "1765111332964",
      days: "88",
      quantity: 90
    },
  ]
}
